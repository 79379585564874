<template>

<TopNavigation step="0" title="Page Not Found" icon="" />

  <div class="generalBox shadow-sm border">
      <div class="row">

          <div class="col-5 col-lg-4 mb-2 text-center">
            <fa icon="triangle-exclamation" style="font-size: 120px"/>
          </div>

          <div class="col-7 col-lg-8 mb-2">
            <div class="fw-bold" style="font-size: 20px">Uh oh...</div>
            <div class="fw-bold mb-2" style="font-size: 20px">I think we're lost.</div>
            <div class="mb-2">The page you're looking for could not be found.</div>
            <div><span class="text-grey">Need help? </span><a href="mailto:wecare@signon.my" ><span class="text-primary">wecare@signon.my</span></a></div>
          </div>

          <div class="col-12"><hr class="my-3"/></div>
          <div class="col-12 text-center">
              <button class="btn btn-secondary" @click="$router.push({ path: '/' })">
                  <fa icon="house" size="lg" class="me-2" />Home
              </button>
          </div>
      </div>
  </div>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'PageNotFound',
  components: {
    TopNavigation
  },
  setup () {

    const route = useRoute()
    const router = useRouter()

    return { route, router }
  }
}
</script>

<style>
</style>